.recipe-item {
  margin: 1rem 1rem;
  background: #a3d9ff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  color: black;
  cursor: pointer;
  border-radius: 15px 50px;
  border: solid aliceblue !important;
  border-width: 0 1px;
  border-style: solid;
  overflow: hidden;
  display:block;
  width: 400px;
  -webkit-transition: color 0.2s;
}

.recipe-item:hover{
  background:rgb(0, 174, 255);
  color: white;

  .tag::after{
    background: rgb(0, 174, 255);
  }

  .tag::before{
    background: rgb(0, 174, 255);
  }
}
.recipe-item-title{
  font-weight: bold;
  font-size: large;
  color: #282c34;
  padding-top: 0.25rem;
}


.tags {
  list-style: none;
  margin: 0;
  overflow: hidden; 
  padding: 0;
  display: flex;
  justify-content: center;
  
}


.tag {
  background: aliceblue;
  border-radius: 3px 0 0 3px;
  color: #555555;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}


.tag::before {
  background: #a3d9ff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag::after {
  background: #a3d9ff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid aliceblue;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: rgb(20, 107, 220);
  color: white;
}

.tag:hover::after {
   border-left-color: rgb(20, 107, 220); 
}




