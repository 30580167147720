.form-control {
  margin: 0.5rem 0;
}

.form-control label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.form-control input {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
  line-height: 1.5rem;
  padding: 0 0.25rem;
}

.form-control input:focus {
  outline: none;
  background: aliceblue;
  border-color: #a9a3ff;
}

.form-control.invalid input {
  
  background: #fad0ec;
  border-color: #8b005d;
  
}

.form-control.invalid label {
  
  color: red;
  
}

.recipe-input {
  margin: 1rem 1rem;
  padding: 0.4rem;
  /*background: #a3d9ff;*/
  background: repeating-linear-gradient(
    45deg,
    #a3d9ff,
    #606dbc 10px,
    #354b75 10px,
    #465298 20px
  );
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  display: inline-flex;
  justify-content: center;
  width: 100%;
  font-size: 30px;
  border-radius: 15px 15px;
  border: solid aliceblue;
  border-width: 0 1px;
  border-style: solid;
  overflow: hidden;
}
@media screen and (max-width:1080px) {
  .recipe-input {
    width:70%;
    margin: 2rem 0rem 3rem 5rem;
    /*top right bottom left */
    font-size: 65px;
  }
}


.centered-content {
  display: flex;
  justify-content: center;
}
@media screen and (max-width:1080px){
  .centered-content{
    display: inline;
  }
}

.search-message {
  color: #000000;
  background-color: #e0eaff;
  max-width: 50%;
  font-size: 30px;
  display: inline-flex;
  justify-content: center;
  margin: 0rem 0rem 0rem 0rem;
  padding: 1rem;
  border-radius: 15px 15px 15px 15px;
  border-color: aliceblue;
  
  border-style: solid;
}

.search{
  padding-right: 1rem;
}

.results{

}

.search-button {
  border-radius: 0px 15px 15px 0px;
  border-color: aliceblue;
  border-width: 0 1px;
  border-style: solid;
  overflow: hidden;
  padding: 1rem;
  
}

.reset{

}

.reset-button {
  border-radius: 15px;
  border: solid rgb(206, 210, 255);
  border-width: 0 1px;
  border-style: solid;
  overflow: hidden;
  padding: 1rem;
  margin-top: 2rem;
  
}

.reset-button:hover{
  cursor: pointer;
  background-color: #354b75;
  color: white;
}