.recipe-list {
  list-style: none;
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  background:#ffffff;
}

.wrapcontainer {
  background-color:#2E4272;
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
}

.itemsperpage {
  display:flex;
  flex-wrap: wrap;
  justify-content: right;
  padding-right: 1rem;
  margin: 0;
}


.container {
  background-color:#ffffff;
  display:block;
  margin: auto;
  padding: auto;
  min-height: 100%;
}

.flex-item {
  
  flex-grow: 1;
}

.fixed {
  border-radius: 15px 15px;
  border: solid aliceblue;
  border-width: 1px 1px;
  border-style: solid;
  padding: 1rem;
  margin: 1rem;
  background-color:#f1f3f8;
}

.list-container{
  border-style:solid;
  border-color: #eff4ff;
  border-bottom: 0;
  border-width: 0.2em;
  border-radius:120px 120px 0px 0px ;
  box-shadow: 0 30px 40px rgba(0,0,0,.2);
  margin: 0rem 0rem 0rem 2rem;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
/* Style The Dropdown Button */
.dropbtn {
  background-color: #2e5772; /*#4CAF50;*/
  color: white;
  padding: 1rem;
  font-size: 25px;
  cursor: pointer;
  right: 0;
  width: 16rem;
  border-bottom: 0;
  border-top:0;
  border-radius: 20px 0px 0px 0px;
  position: relative;
  bottom: -70px;
  z-index: 0;
  

}
@media screen and (max-width:1080px){
  .dropbtn{
    font-size: 40px;
    margin: 0rem 0rem 0rem 0rem;
    bottom: -80px
  }
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
}


/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  font-size: 30px;
  position: absolute;
  background-color: #f9f9f9;
  right: 0;
  min-width: 143px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 2;
  border-radius: 0px 0px 20px 20px;
}

@media screen and (max-width:1080px){
  .dropdown-content{
    font-size: 75px;
  }
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 35px;
}

@media screen and (max-width:1080px){
  .dropdown-content a {
    display: inline-block;
    
    width:12rem;
  }
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: rgb(231, 244, 255);
  

}
@media screen and (max-width:1080px){
  .dropdown-content a:hover {
    background-color: rgb(231, 244, 255);
    width: 12rem;
  
  } 
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.selected {
  border: solid #006eff;
  background-color: #e3f3ff;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #58c3f5;
}

.next-button-image{
  
  cursor: pointer;
  width: 4rem;
}

.next-button-image:hover{
  
  color:white;
  border-color: #a3d9ff;
}

.prev-button-image{
  
  cursor: pointer;
  width: 4rem;

}

.prev-button-image:hover{
  
  color:white;
  border-color: #a3d9ff;
}



.page-number{
  color: white;
  
  padding: 0rem 1rem 0rem 1rem;
  font-size: 2rem;
}