.recipe-view {
    margin: 6rem;
    background: #ffffff;
    display:block;
    width: auto;
  }
@media screen and (max-width:1080px) {
  .recipe-view{
    margin:0rem;
  }
}
  .recipe-title{
    margin: 1rem;
    padding: 1rem;
    font-size: 3rem;
    font-family: cursive;
    
  }

  .recipe-header{
    margin: 1rem 0rem 1rem;
    padding: 1rem;
    font-size: 3rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; 
    min-width: max-content;
    max-width: fit-content;
    
  }
  
  .recipe-view-image {
    margin: 1rem 1rem;
    background: #c1dcff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    color: black;
    border-radius: 50px 50px 0px 0px;
    border: solid rgb(126, 126, 126) !important;
    border-width: 0 1px;
    border-style: solid;
    overflow: hidden;
    display:inline;
    width: 800px;
    
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
  .recipe-ingredients-list{
    list-style: square;
    margin: 1rem;
    padding: 1rem;
    display:list-item;
    
  }
  .recipe-ingredients{
    list-style: none;
    margin: auto;
    padding: auto;
    display:list-item;
    column-count: 3;
    column-gap: 40px;
    column-rule-style: solid;
    column-rule-width: 1px;
    column-rule-color: rgb(50, 94, 107);
    text-align: start;
    font-size: 1.25rem;
    
  }
  
  @media screen and (max-width:1080px) {
    .recipe-ingredients{
      column-count: 1;
    }
  }

  .ingredient-gradient{
    background: repeating-linear-gradient(
      0deg,
      #465298,
      #606dbc
      
    ) !important;
  }
  
  .recipe-instructions{
    display: block;
    white-space: pre-line;
    text-align: start;
    padding: 1rem;
    margin: 0.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    color: black;
    border-radius: 15px 50px;
    border: solid aliceblue !important;
    border-width: 0 1px;
    border-style: solid;
    background: aliceblue;
    
    font-size: 1.5rem;
  }

  @media screen and (max-width:1080px) {
    .recipe-instructions{
      padding: 0rem;
      
    }
  }

  .list-item-ingredient{
    border-radius: 10px 10px 0px 0px;
    border: solid aliceblue !important;
    border-width: 0 1px;
    border-style: solid;
    background: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    color: black;
    margin: 0.75rem;
    padding: 0.5rem;
    max-width: max-content;
  }
  @media screen and (max-width:1080px) {
    .list-item-ingredient {
      max-width: 100%;
    }
  }
  .list-item-ingredient:hover{
    cursor: pointer;
  }
  
  /* Dotted border */
hr.dotted {
    
    margin: 3rem;
    border-top: 3px dotted #282c34;
  }

.video{
  background-color: rgb(216, 216, 216);
  border-radius: 20px;
  position: relative;
  max-width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.back-button-container{
  float: left;
  
  }

.back-button-image{
  cursor: pointer;
  width: 4rem;
  border-radius: 40px;
}

.back-button-image:hover{
  
  color:white;
  border-radius: 15px;
  border-color: #a3d9ff;
}

/*whatdoesthisdo*/
.back-button-image::after{
  background-color: bisque;
}
